/** @format */

import HOC from "../layout/HOC";
import {  } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Alert } from "react-bootstrap";

import axios from "axios";

const data = [
  {
    Lot: "1",
    Product: "Product",
    Quantity: "5",
    Loc: "Delhi",
    LocDate: "11/20/1222",
    Delivey: "Delhi",
    deliveryD: "11/20/1222",
    Vnumber: "1122",
    status: "Complete",
   
  },
  {
    Lot: "2",
    Product: "Product",
    Quantity: "5",
    Loc: "Delhi",
    LocDate: "11/20/1222",
    Delivey: "Delhi",
    deliveryD: "11/20/1222",
    Vnumber: "1122",
    status: "Complete",
   
  },
  {
    Lot: "3 ",
    Product: "Product",
    Quantity: "5",
    Loc: "Delhi",
    LocDate: "11/20/1222",
    Delivey: "Delhi",
    deliveryD: "11/20/1222",
    Vnumber: "1122",
    status: "Complete",
   
  },

];
const TranporterLead = () => {
 
  const [data, setData] = useState([]);
  const [user_id, setId] = useState("");

  const [query, setQuery] = useState("");
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);

 
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        "https://api.agristox.com/api/transport/users"
      );
      console.log(data.data)
      setData(data.data);
    } catch (E) {
      console.log(E);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // const filterData = !query
  //   ? data
  //   : data?.filter(
  //       (i) =>
  //         i?.name?.toLowerCase().includes(query?.toLowerCase()) ||i?.tradeName?.toLowerCase().includes(query?.toLowerCase()) || i?.email?.toLowerCase().includes(query?.toLowerCase()) ||
  //         i?.phoneNumber
  //           ?.toString()
  //           ?.toLowerCase()
  //           .includes(query?.toLowerCase())
  //     );

  const filterData = !query ? data : data?.filter(i => {
    const matchesData = 
        i?.name?.toLowerCase().includes(query?.toLowerCase()) ||
        i?.tradeName?.toLowerCase().includes(query?.toLowerCase()) ||
        i?.email?.toLowerCase().includes(query?.toLowerCase()) ||
        i?.phoneNumber?.toString()?.toLowerCase().includes(query?.toLowerCase());

    const matchesVehicleRoutes = i.vehicle_routes && i.vehicle_routes.some(route =>
        route?.name?.toLowerCase().includes(query?.toLowerCase())
    );

    if (matchesData || matchesVehicleRoutes) {
        return true;
    }

    // Check if any vehicle route matches the query
    if (i.vehicle_routes) {
        for (const route of i.vehicle_routes) {
            if (route?.toLowerCase().includes(query?.toLowerCase())) {
                return true;
            }
        }
    }

    return false;
});

  return (
    <>

    <MyVerticallyCenteredModal3
        show={modalShow2}
        onHide={() => setModalShow2(false)}
    />
    <MyVerticallyCenteredModal1
        show={modalShow1}
        onHide={() => setModalShow1(false)}
    />
    
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ color: "black", fontSize: "1.3rem" }}>All Transporter's Data</p>
        {/* */}
      </div>

      <div style={{ marginTop: "2%" }}>
        <div style={{ color: "black" }}>
          Search:{" "}
          <input
            type={"search"}
            style={{
              border: "1px solid #bfbfbf",
              width: "250px",
              color: "black",
              padding: "5px",
            }}
            placeholder="Search by Name , Phone number.."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>


      <div style={{ overflow: "auto", marginTop: "2%" }}>
        <Table striped bordered hover>            
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Trade Name</th>
              <th>email</th>
              <th>Name</th>
              <th>Join</th>
              <th>Regisered </th>
              <th>Phone Number </th>
              <th>View Vehicle </th>
              <th>Route Vehicle </th>
              <th>Tracking  </th>
            </tr>
          </thead>
          <tbody>
            {filterData?.map((i, index) => (
              <tr key={index}>
                <td> #{index + 1} </td>
                <td>{i.tradeName} </td>
                <td>{i.email} </td>
                <td>{i.name} </td>
                <td>{new Date(i.createdAt).toLocaleDateString()} </td>
                <td>{i.isRegisered ? "Yes" : "No"} </td>
                <td>{i.phoneNumber} </td>
                <td>
                  <Button
                      onClick={() => {
                        setId(i._id);
                        setModalShow2(true);
                      }}
                    >
                      View
                  </Button> </td>
                  <td>{i.vehicle_routes} </td>
                  {/* <td>
                      {i.vehicle_routes.map((name)=>(
                        <span>
                          {name}
                        </span>
                      ))}
                  </td> */}
                <td><Button
                      onClick={() => {
                        setId(i._id);
                        setModalShow1(true);
                      }}
                    >
                      Link
                  </Button></td>

              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );

  function MyVerticallyCenteredModal3(props) {
    const [transData, setTransData] = useState([]);
    const [ transLenght , setTransLength ] = useState("")

    const fetchTransacation = async () => {
      try {
        const { data } = await axios.get(
          `https://api.agristox.com/api/transport/${user_id}`        // need to implement api 
        );
        setTransData(data.data);
        setTransLength(data.length);
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (props.show) {
        fetchTransacation();
      }
    }, [props.show]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            All Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {transLenght === 0 ? (
            <Alert variant="info">No Data Found</Alert>
          ) : (
            <div style={{ width: "100%", overflow: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <td>S.No.</td>
                    <td>Vehicle Number</td>
                    <td>Current Location</td>
                    <td>Vehicle Type</td>
                    <td>Capacity</td>
                    <td>All India Permit</td>
                  </tr>
                </thead>
                <tbody>
                  {transData.map((i, index) => (
                    <tr key={index}>
                      <td>#{index + 1} </td>
                      <td> {i?.vehicleNumber} </td>
                      <td> {i?.currentLocation} </td>
                      <td> {i?.behicle_type} </td>
                      <td> {i?.capacity} </td>
                      <td> {i?.all_india_permit ? 'True' : 'False'} </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  function MyVerticallyCenteredModal1(props) {
    const [vehicleNumber, setTransportLink] = useState("");
  
    const fetchTransacation = async () => {
      try {
        const { data } = await axios.post(
          `https://api.agristox.com/api/transport/update/transportLink`,
          {
            _id: user_id, 
            tranksportLink: vehicleNumber,
          }
        );
        console.log(data);
        props.onHide();
      } catch (e) {
        console.log(e);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      await fetchTransacation();
    };
  
    useEffect(() => {
      if (props.show) {
        setTransportLink("");
      }
    }, [props.show]);
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Tracking Link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label style={{ marginRight: "10px" }}><b>Enter tracking link :</b></label>
            <input
              type="text"
              value={vehicleNumber}
              onChange={(e) => setTransportLink(e.target.value)}
              placeholder="Enter vehicle number"
              style={{
                marginBottom: "15px",
                padding: "8px",
                border: "2px solid #ccc",
                borderRadius: "4px",
                width: "100%",
              }}
            />
            <button
              type="submit"
              style={{
                backgroundColor: "blue",
                color: "white",
                border: "none",
                padding: "10px 20px",
                margin: "10px auto 0", 
                display: "block",     
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  
};

export default HOC(TranporterLead);
