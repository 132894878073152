/** @format */

import React, { useState, useCallback, useEffect } from "react";
import HOC from "../layout/HOC";
import { useParams } from "react-router-dom";
import axios from "axios";

const KYC = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  // Fetch Data
  const fetchHandler = useCallback(async () => {
    try {
      const { data } = await axios.get(
        // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/kyc/user/${id}`
        `https://api.agristox.com/api/kyc/user/${id}`
      );
      setData(data);
    } catch (e) {
      console.log(e);
    }
  }, [id]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  let UserName = data?.data?.[1]?.user?.tradeName;
  return (
    <>
      <section>
        <div className="pb-4 sticky top-0  w-full flex justify-between items-center bg-white">
          <span className="tracking-widest text-slate-900 font-semibold uppercase ">
             Document Details :
          </span>
        </div>
      </section>

      <div>
        <div className="sup">
          {/* <div className="left">
            <img
              src={
                data?.data?.[1]?.user?.photo
                  ? data?.data?.[1]?.user?.photo
                  : ""
              }
              alt="user"
            />
            <img
              src={
                data?.data?.[1]?.aadharFrontImage
                  ? data?.data?.[1]?.aadharFrontImage
                  : ""
              }
              alt="user"
            />
            <img
              src={
                data?.data?.[1]?.panImage
                  ? data?.data?.[1]?.panImage
                  : ""
              }
              alt="user"
            />
          </div> */}
          <div className="mid">
            <p>Name : {data?.bank?.userId?.name} </p>
            <p>Phone Number :  {data?.bank?.userId?.phoneNumber} </p>
            {/* <p>Address : {data?.data?.[1]?.user?.address} </p> */}
            <p>Country : {data?.bank?.userId?.address?.[0]?.country} </p>
            <p>Email : {data?.bank?.userId?.email} </p>
            <p>Trade Name : {data?.bank?.userId?.tradeName} </p>
            <p>Role : {data?.bank?.userId?.role} </p>
          </div>
          <div className="mid">
            <p>Adhaar Number : {data?.data?.[0]?.aadharNumber} </p>
            <p>Pan Number : {data?.data?.[0]?.panNumber} </p>
            <p>City : {data?.data?.[0]?.city} </p>
            <p>District : {data?.data?.[0]?.district} </p>
            <p>Gst Number : {data?.data?.[0]?.gstNumber} </p>
            <p>Pin Code : {data?.data?.[0]?.pincode} </p>
            <p>State : {data?.data?.[0]?.state} </p>
          </div>
          <div className="right">
            <p> Bank : {data?.bank?.bankName}</p>
            <p> Account Number : {data?.bank?.accountNumber}</p>
            <p> Account Holder Name : {data?.bank?.accountHolderName}</p>
            <p> IFSC Code : {data?.bank?.ifscCode}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(KYC);
