/** @format */

import HOC from "../../layout/HOC";
import Table from "react-bootstrap/Table";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import axios from "axios";
import React, { useState, useEffect } from "react";

const pay = [
  {
    name: "React",
    crop: "Product",
    Amount: 4521,
  },
  {
    name: "React",
    crop: "Product",
    Amount: 4521,
  },
  {
    name: "React",
    crop: "Product",
    Amount: 4521,
  },
];

const Orders = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [payment, setPayment] = useState("");
  // const [remark, setRemark] = useState("");
  const [order, setOrder] = useState('');

  const [ewayBillUrl, setEwayBillUrl] = useState('');
  const generateEwayBill = async (orderId) => {
    try {
        const response = await axios.post('https://api.agristox.com/api/order/eway-invoice', { orderId });
        const url = response.data.data;
        console.log("THIS IS E_WAY URL : : ", url)
        const link = document.createElement('a');
        link.href = `https://${url}`;
        link.download = 'eWayBill.pdf';  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // setEwayBillUrl(url);
        // setModalShow(true);
    } catch (error) {
        console.error('Error generating e-way bill:', error);
    }
};

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        "https://api.agristox.com/api/admin/get_order_list"
      );
      setData(data.data);
    } catch (E) {
      console.log(E);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // const filterData = !query
  // ? data
  // : data
  // Filter Data
  const filterData = !query
    ? data
    : data?.filter(
        (i) =>
          i?.supliyer_detail.tradeName
            ?.toLowerCase()
            .includes(query?.toLowerCase()) ||
          i?.supliyer_detail.phoneNumber
            ?.toString()
            ?.toLowerCase()
            .includes(query?.toLowerCase()) ||
          i?.buyer_detail.tradeName
            ?.toLowerCase()
            .includes(query?.toLowerCase()) ||
          i?.buyer_detail.phoneNumber
            ?.toString()
            ?.toLowerCase()
            .includes(query?.toLowerCase()) ||
          i?.bid_detail.lotId
          ?.toString()
          ?.toLowerCase()
          .includes(query?.toLowerCase())
      );
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <MyVerticallyCenteredModal3
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />

      <MyVerticallyCenteredModal4
        show={modalShow3}
        onHide={() => setModalShow3(false)}
        order={order}
      />
      <MyVerticallyCenteredModal5
        show={modalShow4}
        onHide={() => setModalShow4(false)}
        order={order}
      />

      <section>
        <div className="pb-4 sticky top-0  w-full flex justify-between items-center bg-white">
          <span className="tracking-widest text-slate-900 font-semibold uppercase ">
            All Payments
          </span>
        </div>
      </section>
      <div style={{ marginTop: "2%" }}>
        <div style={{ color: "black" }}>
          Search:{" "}
          <input
            type={"search"}
            style={{
              border: "1px solid #bfbfbf",
              width: "250px",
              color: "black",
              padding: "5px",
            }}
            placeholder="Search by Name, Phone No."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      <Table striped bordered hover style={{ marginTop: "2%" }}>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Date</th>
            <th>Lot Id</th>
            <th>Supplier Trade Name</th>
            <th>Supplier Address</th>
            <th>Supplier Phone Number</th>
            <th>Crop</th>
            <th>Quantity</th>
            <th>Buyer</th>
            <th>Buyer's address</th>
            <th>Buyer's Number</th>
            <th>Advance Payment</th>
            <th>Due Payment</th>
            <th>Payment Id</th>
            <th>Supplier Payment Request </th>
            <th>Inspection</th>
            <th>Acknowledge Buyer full payment</th>
            <th>Assign Vehicle</th>
            <th>Assigned Vehicle Name</th>
            <th>Vehicle Amount</th>
            <th>Expected Pick up Date</th>
            <th>Expected Drop Date</th>
            <th>delivery status</th>
            <th>Generate E-Way</th>
            <th>Tracking ID No.</th>
          </tr>
        </thead>
        <tbody>
          {filterData.map((i, index) => (
            <tr>
              <td> #{index + 1} </td>
              <td>
                {" "}
                {i.updatedAt
                  ? new Date(i.updatedAt).toLocaleDateString()
                  : ""}{" "}
              </td>

              <td> {i.bid_detail.lotId}</td>
              <td> {i.supliyer_detail.tradeName} </td>
              <td> {i.supliyer_detail.address} </td>
              <td> {i.supliyer_detail.phoneNumber} </td>
              <td> {i.crop_detail.name} </td>
              <td> {i.bid_detail.quantity}</td>
              <td> {i.buyer_detail.tradeName}</td>
              <td> {i.buyer_detail.deliveryAddress}</td>
              <td> {i.buyer_detail.phoneNumber}</td>
              <td> {i.payment_detail.amount}</td>
              <td> -- </td>
              <td> {i.payment_detail.payment_id}</td>
              <td> {i.bid_detail.request_full_payment == true ? 'Yes' : '--'}</td>

              <td>
                {" "}
                {i.inspection === true ? (
                  <Button
                    onClick={() => {
                      // setId(i._id);
                      setModalShow3(true);
                      setOrder(i);
                    }}
                  >
                    View
                  </Button>
                ) : (
                  "No"
                )}{" "}
              </td>
              <td>
                {" "}
                {i.status == 0 ? (
                  "Empty"
                ) : (
                  <Button
                    onClick={() => {
                      // setId(i._id);
                      setModalShow2(true);
                      setPayment(i);
                    }}
                    style={{ backgroundColor: i.buyer_full_payment_acknowledge == 1 ? 'blue' : 'grey' }}
                  >
                    View
                  </Button>
                )}{" "}
              </td>
              {/* <td> <Button
                        onClick={() => {
                          // setId(i._id);
                          setShow(true);
                        }}
                      >
                        View
                      </Button> </td> */}

              <td>
                {" "}
                {
                  <Button
                    onClick={() => {
                      setModalShow(true);
                      setOrderId(i._id);
                    }}
                  >
                    Assign Vehicle
                  </Button>
                }
              </td>
              <td>
                {" "}
                {i.order_vehicle_id
                  ? i.order_vehicle_id.vehicleNumber
                  : "Not Assigned yet"}
              </td>
              <td>
                {" "}
                {i.order_vehicle_id
                  ? i.order_vehicle_id.transport_amount
                  : "Not Assigned yet"}
              </td>
              <td>
                {i.order_vehicle_id
                  ? new Date(
                      i.order_vehicle_id.expected_pickup_date
                    ).toLocaleDateString()
                  : "Not Assigned yet"}
              </td>
              <td>
                {i.order_vehicle_id
                  ? new Date(
                      i.order_vehicle_id.expected_delivery_date
                    ).toLocaleDateString()
                  : "Not Assigned yet"}
              </td>
              <td>
                {i.delivery_status == 0
                  ? "Pending"
                  : i.delivery_status == 1
                  ? "Pickuped"
                  : i.delivery_status == 2
                  ? "Delivered"
                  : "--"}
              </td>
              <td>
              {" "}
                {i.delivery_status == 1 || i.delivery_status == 2 ? (
                  // <Button
                  //   onClick={() => {
                  //     // setId(i._id);
                  //     setModalShow3(true);
                  //     setOrder(i);
                  //   }}
                  // >
                  //   Generate
                  // </Button>
                  <Button
                      onClick={() => generateEwayBill(i._id)}
                  >
                      Generate
                  </Button>
                ) : (
                  "--"
                )}{" "}
              </td>
              
              <td>
                  <Button
                    onClick={() => {
                      setOrderId(i._id);
                      setModalShow4(true);
                    }}
                  >
                    Tracking ID
                  </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );

  function MyVerticallyCenteredModal3(props) {
    const [transData, setTransData] = useState([]);
    const [transLenght, setTransLength] = useState("");
    const [remark, setRemark] = useState("");

    const fetchTransacation = async () => {
      try {
        const { data } = await axios
          .get
          // `https://api.agristox.com/api/createbid/payment/bidId/`        // need to implement api
          ();
        setTransData(data);
        setTransLength(data.result.length);
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (props.show) {
        fetchTransacation();
      }
    }, [props.show]);

    const postHandlerReject = async (status, remark) => {
      const sendData = {
        status: status,
        remark: remark,
        order_id: payment._id,
      };
      const data = await axios.post(
        `https://api.agristox.com/api/admin/buyer_payment_acknowledge`,
        sendData
      );
      setModalShow2(false);
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Acknowlege Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {transLenght === 0 ? (
            <Alert variant="info">No Transaction Found</Alert>
          ) : (
            <div style={{ overflow: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <td>SNo.</td>
                    {/* <td>Buyer Name</td>
                    <td>Buyer Phone Number</td>
                    <td>Supplier Name</td>
                    <td>Supplier Phone Number</td> */}
                    <td>Payment Id</td>
                    <td>Advance Paid</td>
                    <td>Pending Amount</td>
                    <td>Total Amount</td>
                    <td>Payment Mode</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td># 1</td>
                    <td>{payment?.payment_detail?.payment_id}</td>
                    <td>{payment?.payment_detail?.amount}</td>
                    <td>{payment.buyer_full_payment_acknowledge === 2 ? 0 : ((payment.total_amount || 0) + (payment.tax_amount || 0) + (payment.service_charge_amount || 0)- (payment.payment_detail?.amount || 0))}</td>
                    <td>{((parseFloat(payment?.total_amount) || 0) + (parseFloat(payment?.tax_amount) || 0) + (parseFloat(payment?.service_charge_amount) || 0)).toFixed(2)}</td>
                    <td>{payment?.payment_detail?.payment_type}</td>
                    <td>{payment?.payment_detail?.status}</td>
                  </tr>
                </tbody>
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                  placeholder="remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent the default behavior of the Enter key
                      // Handle your logic here, e.g., call postHandlerReject
                    }
                  }}
                />
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <i
                    className="fa-solid fa-circle-xmark"
                    style={{
                      fontSize: "40px",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      postHandlerReject(3, remark);
                    }}
                  ></i>
                </div>
                <div>
                  <i
                    className="fa-solid fa-circle-check"
                    style={{
                      fontSize: "40px",
                      color: "green",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      postHandlerReject(2, remark);
                    }}
                  ></i>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  function MyVerticallyCenteredModal4(props) {
    const [transData, setTransData] = useState([]);
    const [transLenght, setTransLength] = useState("");

    const fetchTransacation = async () => {
      try {
        const { data } = await axios
          .get
          // `https://api.agristox.com/api/createbid/payment/bidId/`        // need to implement api
          ();
        setTransData(data);
        setTransLength(data.result.length);
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (props.show) {
        fetchTransacation();
      }
    }, [props.show]);

    const postHandlerReject = async (status, remark) => {
      const sendData = {
        status: status,
        remark: remark,
        order_id: payment._id,
      };
      const data = await axios.post(
        `https://api.agristox.com/api/admin/buyer_payment_acknowledge`,
        sendData
      );
      setModalShow2(false);
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Inspection
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {transLenght === 0 ? (
            <Alert variant="info">No Inspection Found</Alert>
          ) : (
            <div style={{ overflow: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <td>SNo.</td>
                    <td>lotID</td>
                    <td>name</td>
                    <td>price</td>
                    <td>service</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td># 1</td>
                    <td>{order?.company?.lotID}</td>
                    <td>{order?.company?.name}</td>
                    <td>{order?.company?.price}</td>
                    <td>{order?.company?.service}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  function MyVerticallyCenteredModal(props) {
    const [pickUpDate, setpickUpDate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [transportAmount, setTransportAmount] = useState("");
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
  
    
    const handleSelectChange = (e) => {
      setSelectedUserId(e.target.value);
    };
  
    const handleSearchInputChange = (e) => {
      setSearchQuery(e.target.value);
    };
  
    const filteredUsers = users.filter(user =>
      user.vehicleNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get("https://api.agristox.com/api/transport");
        setUsers(data.data);
      } catch (e) {
        console.log(e);
      }
    };

    const editHandler = async (e) => {
      e.preventDefault();
      try {
        const sendData = {
          order_id: orderId,
          vehicle_id: selectedUserId,
          expected_pickup_date: pickUpDate,
          expected_delivery_date: deliveryDate,
          transport_amount: transportAmount,
        };
        const { data } = await axios.post(
          `https://api.agristox.com//api/order/assign_vehicle`,
          sendData
        );
        console.log("RESPONSE DATA", data);
        // alert("Edited");
        setModalShow(false);
        fetchData();
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (props.show === true) {
        fetchUsers();
      }
    }, [props.show]);

    const [user, setU] = useState("");
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Assign Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editHandler}>
            <Form.Group className="mb-3">
              <Form.Label>Vehicle </Form.Label>

              <div>
                  <input
                    type="text"
                    placeholder="Search Vehicle Number"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    style={{ border: '1px solid black', width : '100%' }}
                  />
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    value={selectedUserId}
                  >
                    <option hidden>-- Select Vehicle --</option>
                    {filteredUsers.map((user, index) => (
                      <option key={index} value={user._id}>
                        {user.vehicleNumber}
                      </option>
                    ))}
                  </Form.Select>
              </div>
{/*               
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setU(e.target.value)}
              >
                <option hidden>-- Select Vehicle --</option>
                {users?.map((user, index) => (
                  <option key={index} value={user._id}>
                    {" "}
                    {user.vehicleNumber}{" "}
                  </option>
                ))}
              </Form.Select> */}

              <Form.Label>Expected Pick up Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setpickUpDate(e.target.value)}
              />
              <Form.Label>Expected Delivery Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
              <Form.Label>Transport Amount</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setTransportAmount(e.target.value)}
              />
            </Form.Group>

            <Button type="submit" style={{ marginLeft: "40%" }}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  function MyVerticallyCenteredModal5(props) {
    const [vehicleNumber, setTrackingNumber] = useState("");
  
    const fetchTransacation = async () => {
      try {
        const { data } = await axios.post(
          `https://api.agristox.com/api/admin/update_order_tracking_id_no`,
          {
            _id: orderId, 
            tracking_id_no: vehicleNumber,
          }
        );
        props.onHide();
        console.log(data);

      } catch (e) {
        console.log(e);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      await fetchTransacation();
    };
  
    useEffect(() => {
      if (props.show) {
        setTrackingNumber(""); 
      }
    }, [props.show]);
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Tracking ID Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label style={{ marginRight: "10px" }}><b>Enter Tracking Id here :</b></label>
            <input
              type="text"
              value={vehicleNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              placeholder="Enter Tracking Id number"
              style={{
                marginBottom: "15px",
                padding: "8px",
                border: "2px solid #ccc",
                borderRadius: "4px",
                width: "100%",
              }}
            />
            <button
              type="submit"
              style={{
                backgroundColor: "blue",
                color: "white",
                border: "none",
                padding: "10px 20px",
                margin: "10px auto 0", 
                display: "block",     
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  

};

export default HOC(Orders);
