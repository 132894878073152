/** @format */

import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import HOC from "../layout/HOC";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const CommonBid = () => {
    const { role } = useParams();
    const [modalShow, setModalShow] = React.useState(false);
    const [id, setId] = useState("");
    const [userId, setUserId] = useState("");
    const [data, setData] = useState("");
    const [secondTab, setSecondTab] = useState(false);
    const [show, setShow] = useState(false);
    const [lotIdData, setLotIdData] = useState([]);
    const [query, setQuery] = useState("");
    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);
    const [modalShow4, setModalShow4] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);
    const [idToEdit, setIdToEdit] = useState(null);

    const [bidId, setBidId] = useState("");
    const deleteData = async (id)=>{
        try {
            const { data } = await axios.post(
                `https://api.agristox.com/api/createbid/delete_bid_admin/${id}`
            ).then((res)=>{
                console.log(res,"kkkkkkk");
                fetchHandler()
            })
        }catch(err){

        }
    }
    const fetchHandler = useCallback(async () => {
        try {
            const { data } = await axios.get(
                // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/createbid/${role}`
                `https://api.agristox.com/api/createbid/`
            );
            setData(data);
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }, [role]);

    useEffect(() => {
        fetchHandler();
    }, [fetchHandler]);

    const fetchSingleBid = async (lotId) => {
        try {
            const { data } = await axios.post(
                // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev//createbid/lotId`,
                `https://api.agristox.com/api/createbid/lotId`,
                {
                    lotId,
                }
            );
            setLotIdData(data);
            setSecondTab(true);
        } catch (e) {
            console.log(e);
        }
    };

    function MyVerticallyCenteredModal(props) {
        const [expiretime, setExpireTime] = useState("");

        const putHandler = async (e) => {
            e.preventDefault();
            try {
                const { data } = await axios.put(
                    // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/createbid/verifyByAdmin/${id}`,
                    `https://api.agristox.com/api/createbid/verifyByAdmin/${id}`,
                    { expiretime }
                );
                console.log(data);
                toast.success("Success");
                props.onHide();
                fetchHandler();
            } catch (e) {
                console.log(e);
            }
        };

        return (
            <Modal
                {...props}
                size="lg-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={putHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label>Expiry Time</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                onChange={(e) => setExpireTime(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="outline-success" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }

    // Top 10 Bidder
    function BidModal(props) {
        const [each, setEach] = useState([]);

        const fetchBidder = useCallback(async () => {
            try {
                const { data } = await axios.post(
                    // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/createbid/waitlist/bid/${id}` , {
                    `https://api.agristox.com/api/createbid/waitlist/bid/${id}`,
                    {
                        user: userId,
                    }
                );
                setEach(data);
            } catch (e) {
                console.log(e);
            }
        }, []);

        useEffect(() => {
            if (props.show === true) {
                fetchBidder();
            }
        }, [fetchBidder, props.show]);

        const postHandlerAccept = async (id, userId) => {
            try {
                const { data } = await axios.post(
                    // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/createbid/payment/status/${id}`,
                    // `https://api.agristox.com/api/createbid/payment/status/${id}`,
                    `https://api.agristox.com/api/createbid/accept/${id}/${userId}`,
                    {}
                );
                console.log(data);
                fetchBidder();
            } catch (e) {
                console.log(e);
            }
        };
        const postHandlerReject = async (id, userId) => {
            try {
                const { data } = await axios.post(
                    // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/createbid/payment/status/${id}`,
                    // `https://api.agristox.com/api/createbid/payment/status/${id}`,
                    `https://api.agristox.com/api/createbid/cancel/${id}/${userId}`,
                    {}
                );
                console.log(data);
                fetchBidder();
            } catch (e) {
                console.log(e);
            }
        };

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Top 10 Bidder
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>SNo.</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Contact Number</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {each?.message?.[0]?.top?.map((i, index) => (
                                <tr key={index}>
                                    <td> #{index + 1} </td>
                                    <td> {i.user?.tradeName} </td>
                                    <td> {i.highestBid} </td>
                                    <td> {i.user.phoneNumber} </td>
                                    <td> {i.status} </td>
                                    <td>
                                        <span className="d-flex gap-2">
                                            <i
                                                className="fa-solid fa-circle-xmark"
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => {
                                                    postHandlerReject(i?.bidDetail, i?.user?._id);
                                                }}
                                            ></i>
                                            <i
                                                className="fa-solid fa-circle-check"
                                                style={{ color: "green", cursor: "pointer" }}
                                                onClick={() => {
                                                    postHandlerAccept(
                                                        i._id,
                                                        each?.message?.[0]?.user._id
                                                    );
                                                }}
                                            ></i>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }

    const filterData = !query
        ? data?.data
        : data?.data?.filter(
            (i) =>
                i?.user_id?.tradeName?.toLowerCase().includes(query?.toLowerCase()) ||
                i?.crop?.name?.toLowerCase().includes(query?.toLowerCase()) ||
                i?.lotId?.toString()?.toLowerCase().includes(query?.toLowerCase())
        );

    function MyVerticallyCenteredModal3(props) {
        const [transData, setTransData] = useState([]);
        const [transLenght, setTransLength] = useState("");

        const fetchTransacation = async () => {
            try {
                const { data } = await axios.get(
                    // `https://djqtflksic.execute-api.ap-south-1.amazonaws.com/dev/createbid/payment/bidId/${id}`
                    `https://api.agristox.com/api/createbid/payment/bidId/${id}`
                );
                setTransData(data);
                setTransLength(data.result.length);
            } catch (e) {
                console.log(e);
            }
        };

        useEffect(() => {
            if (props.show) {
                fetchTransacation();
            }
        }, [props.show]);

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Transaction
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {transLenght === 0 ? (
                        <Alert variant="info">No Transaction Found</Alert>
                    ) : (
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <td>SNo.</td>
                                        <td>Buyer Name</td>
                                        <td>Buyer Phone Number</td>
                                        <td>Supplier Name</td>
                                        <td>Supplier Phone Number</td>
                                        <td>Amount Paid</td>
                                        <td>Pending Amount</td>
                                        <td>Total Amount</td>
                                        <td>Payment Mode</td>
                                        <td>Status</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transData?.result?.map((i, index) => (
                                        <tr key={index}>
                                            <td>#{index + 1} </td>
                                            <td> {i.buyerId?.tradeName} </td>
                                            <td> {i.buyerId?.phoneNumber} </td>
                                            <td> {i.supplierId?.tradeName} </td>
                                            <td> {i.supplierId?.phoneNumber} </td>
                                            <td> {i.amount} </td>
                                            <td> {i.pending} </td>
                                            <td> {i.pending + i.amount} </td>
                                            <td> {i.mode} </td>
                                            <td> {i.status} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }

    function MyVerticallyCenteredModal4(props) {
        const [transData, setData] = useState([]);
        const [remark, setRemark] = useState("");

        const postHandlerAccept = async (id, remark) => {
            try {
                console.log("This is Handler Accept", id);
                console.log(remark);
                const { data } = await axios.post(
                    `https://api.agristox.com/api/placebid/approve_reject`,
                    {
                        _id: id,
                        status: 1,
                        amount: remark,
                    }
                );
                console.log(data);
                props.onHide();
                // setModalShow3(false);
            } catch (e) {
                console.log(e);
            }
        };
        const postHandlerReject = async (id, remark) => {
            try {
                console.log("This is Handler Accept", id);
                console.log(remark);
                const { data } = await axios.post(
                    `https://api.agristox.com/api/placebid/approve_reject`,
                    {
                        // _id: transData[0]?._id,
                        _id: id,
                        status: 2,
                        amount: remark,
                    }
                );
                console.log(data);
                props.onHide();
            } catch (e) {
                console.log(e);
            }
        };

        const fetchTransacation = async (remark) => {
            try {
                const { data } = await axios.get(
                    `https://api.agristox.com/api/placebid/value_addition_list/${bidId}`
                );
                setData(data.result);
            } catch (e) {
                console.log(e);
            }
        };

        useEffect(() => {
            if (props.show) {
                fetchTransacation();
            }
        }, [props.show]);

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Value Addition
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: "100%", overflow: "auto" }}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <td>AdMixture</td>
                                    <td>Comment</td>
                                    <td>Grade</td>
                                    <td>Moisture</td>
                                    <td>Packing</td>
                                    <td>Quantity</td>
                                </tr>
                            </thead>
                            <tbody>
                                {transData?.map((i, index) => (
                                    <tr key={index}>
                                        <td>{i?.admixture}</td>
                                        <td>{i?.comment}</td>
                                        <td>{i?.grade}</td>
                                        <td>{i?.moisture}</td>
                                        <td>{i?.packing}</td>
                                        <td>{i?.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    {/* )} */}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
                <span className="text-center">
                    {/* Input field */}
                    <input
                        type="text"
                        placeholder="Enter amount here.."
                        style={{
                            border: "1px solid #ccc", // Add a 1px solid border with a gray color
                            borderRadius: "5px", // Add rounded corners (optional)
                            padding: "5px", // Add some padding (optional)
                            marginRight: "10px", // Adjust margin as needed
                            marginTop: "5px", // Add a 5px gap from the top
                            marginBottom: "20px", // Add a 5px gap from the top
                        }}
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    />

                    <div>
                        {/* "Reject" button */}
                        <i
                            className="fa-solid fa-circle-xmark"
                            style={{
                                fontSize: "40px",
                                color: "red",
                                cursor: "pointer",
                                marginRight: "20px",
                            }}
                            onClick={() => {
                                postHandlerReject(transData[0]?._id, remark);
                            }}
                        ></i>

                        {/* "Accept" button */}
                        <i
                            className="fa-solid fa-circle-check"
                            style={{ fontSize: "40px", color: "green", cursor: "pointer" }}
                            onClick={() => {
                                postHandlerAccept(transData[0]?._id, remark);
                            }}
                        ></i>
                    </div>
                </span>
            </Modal>
        );
    }

    // function MyVerticallyCenteredModal5(props) {
    //     // State to hold form data
    //     // console.log('HIiiiiiiiiiiiiiiiiiiiiiiiiii')
    //     // const [formData, setFormData] = useState({
    //     //     firstName: "",
    //     //     lastName: "",
    //     //     email: "",
    //     //     age: "",
    //     //     favoriteColor: "",
    //     //     phoneNumber: "",
    //     //     country: "India",
    //     //     city: "Delhi",
    //     // });

    //     // // Handle form field changes
    //     // const handleChange = (e) => {
    //     //     const { name, value } = e.target;
    //     //     setFormData((prevState) => ({
    //     //         ...prevState,
    //     //         [name]: value,
    //     //     }));
    //     // };

    //     // // Handle form submission
    //     // const handleSubmit = (e) => {
    //     //     e.preventDefault();
    //     //     console.log("Form Data Submitted:", formData);
    //     //     // Add your form submission logic here
    //     // };

    //     // return (
    //     //     <div {...props}>
    //     //         <form onSubmit={handleSubmit}>
    //     //             <div>
    //     //                 <label htmlFor="firstName">First Name:</label>
    //     //                 <input
    //     //                     type="text"
    //     //                     id="firstName"
    //     //                     name="firstName"
    //     //                     value={formData.firstName}
    //     //                     onChange={handleChange}
    //     //                 />
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="lastName">Last Name:</label>
    //     //                 <input
    //     //                     type="text"
    //     //                     id="lastName"
    //     //                     name="lastName"
    //     //                     value={formData.lastName}
    //     //                     onChange={handleChange}
    //     //                 />
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="email">Email:</label>
    //     //                 <input
    //     //                     type="email"
    //     //                     id="email"
    //     //                     name="email"
    //     //                     value={formData.email}
    //     //                     onChange={handleChange}
    //     //                 />
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="age">Age:</label>
    //     //                 <input
    //     //                     type="number"
    //     //                     id="age"
    //     //                     name="age"
    //     //                     value={formData.age}
    //     //                     onChange={handleChange}
    //     //                 />
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="favoriteColor">Favorite Color:</label>
    //     //                 <input
    //     //                     type="text"
    //     //                     id="favoriteColor"
    //     //                     name="favoriteColor"
    //     //                     value={formData.favoriteColor}
    //     //                     onChange={handleChange}
    //     //                 />
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="phoneNumber">Phone Number:</label>
    //     //                 <input
    //     //                     type="tel"
    //     //                     id="phoneNumber"
    //     //                     name="phoneNumber"
    //     //                     value={formData.phoneNumber}
    //     //                     onChange={handleChange}
    //     //                 />
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="country">Country:</label>
    //     //                 <select
    //     //                     id="country"
    //     //                     name="country"
    //     //                     value={formData.country}
    //     //                     onChange={handleChange}
    //     //                 >
    //     //                     <option value="India">India</option>
    //     //                     <option value="USA">USA</option>
    //     //                     <option value="Australia">Australia</option>
    //     //                     {/* Add more countries as options here */}
    //     //                 </select>
    //     //             </div>
    //     //             <div>
    //     //                 <label htmlFor="city">City:</label>
    //     //                 <select
    //     //                     id="city"
    //     //                     name="city"
    //     //                     value={formData.city}
    //     //                     onChange={handleChange}
    //     //                 >
    //     //                     <option value="Delhi">Delhi</option>
    //     //                     <option value="Mumbai">Mumbai</option>
    //     //                     <option value="Bangalore">Bangalore</option>
    //     //                     {/* Add more cities as options here */}
    //     //                 </select>
    //     //             </div>
    //     //             <button type="submit">Submit</button>
    //     //         </form>
    //     //     </div>
    //     // );
    // }

    function MyVerticallyCenteredModal5(props) {
        const [dataToEdit, setFormData] = useState({
            expectedRate: '',
            quantity: '',
            totalBags: '',
            moisture: '',
            adMixture: '',
            foreignMatter: '',
            variety: '',
            grade: ''
        });
    
        useEffect(() => {
            if (props.editData) {
                setFormData({
                    expectedRate: props.editData?.expectedRate || '',
                    quantity: props.editData?.quantity || '',
                    totalBags: props.editData?.totalBags || '',
                    moisture: props.editData?.valueAdditionData[0]?.moisture || '',
                    grade: props.editData?.grade || ''
                });
            }
        }, [props.editData]);
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        };
    
        const putHandler = async (e) => {
            e.preventDefault();
            try {
                const config = {
                    headers: {
                        'content-type': 'application/json'
                    }
                };
                const payload = {
                    expectedRate: dataToEdit.expectedRate,
                    quantity: dataToEdit.quantity,
                    totalBags: dataToEdit.totalBags,
                    moisture: dataToEdit.moisture,
                    grade: dataToEdit.grade
                };
                const { data } = await axios.put(
                    `https://api.agristox.com/api/createbid/update_bid_admin/${idToEdit}`,
                    JSON.stringify(payload),
                    config
                );
                console.log(data);
                toast.success("Success");
                props.onHide();
                fetchHandler();
            } catch (e) {
                console.log(e);
            }
        };
    
        
        return (
            <Modal
                {...props}
                size="lg-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit common bid
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={putHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label>Expected Bid Amount</Form.Label>
                            <Form.Control
                                type="text"
                                name="expectedRate"
                                value={dataToEdit.expectedRate}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                                type="text"
                                name="quantity"
                                value={dataToEdit.quantity}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Bags</Form.Label>
                            <Form.Control
                                type="text"
                                name="totalBags"
                                value={dataToEdit.totalBags}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Moisture</Form.Label>
                            <Form.Control
                                type="text"
                                name="moisture"
                                value={dataToEdit.moisture}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Ad Mixture</Form.Label>
                            <Form.Control
                                type="text"
                                name="adMixture"
                                value={dataToEdit.adMixture}
                                onChange={handleChange}
                            />
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <Form.Label>Grade</Form.Label>
                            <Form.Control
                                as="select"
                                name="grade"
                                value={dataToEdit.grade}
                                onChange={handleChange}
                            >
                                <option value="Organic">Organic</option>
                                <option value="AgriStox Super">AgriStox Super</option>
                                <option value="Good Grade">Good Grade</option>
                                <option value="Farm Grade (FAQ)">Farm Grade (FAQ)</option>
                                <option value="Salem">Salem</option>
                            </Form.Control>
                        </Form.Group>
                        <Button variant="outline-success" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <BidModal show={show} onHide={() => setShow(false)} />

            <MyVerticallyCenteredModal3
                show={modalShow2}
                onHide={() => setModalShow2(false)}
            />
            <MyVerticallyCenteredModal4
                show={modalShow3}
                onHide={() => setModalShow3(false)}
            />
            <MyVerticallyCenteredModal5
                show={modalShow4}
                onHide={() => setModalShow4(false)}
                editData={dataToEdit} // Pass the data you want to edit here
                id={idToEdit} 
            />

            <section>
                <div className="pb-4 sticky top-0  w-full flex justify-between items-center bg-white">
                    <span className="tracking-widest text-slate-900 font-semibold uppercase ">
                        {data?.data?.[0]?.supplierData?.tradeName} Bids
                    </span>
                </div>
            </section>

            {secondTab ? (
                <>
                    <Button
                        onClick={() => setSecondTab(false)}
                        variant="outline-success"
                        style={{ marginBottom: "10px" }}
                    >
                        Back
                    </Button>
                    <div
                        style={{
                            overflow: "auto",
                        }}
                    >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Lot Id</th>
                                    <th>Product Name</th>
                                    <th>Variety</th>
                                    <th>Grade</th>
                                    <th>Quantity</th>
                                    <th>Total Bags</th>
                                    <th> Moisture </th>
                                    {/* <th> Ad Mixture </th> */}
                                    {/* <th>Foreign Matter </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> {lotIdData?.result?.[0]?.lotId} </td>
                                    <td> {lotIdData?.result?.[0]?.crop?.name} </td>
                                    <td> {lotIdData?.result?.[0]?.variety} </td>
                                    <td> {lotIdData?.result?.[0]?.grade} </td>
                                    <td> {lotIdData?.result?.[0]?.quantity} </td>
                                    <td> {lotIdData?.result?.[0]?.totalBags} </td>
                                    <td> {lotIdData?.result?.[0]?.moisture} </td>
                                    {/* <td> {lotIdData?.result?.[0]?.extraneous} </td> */}
                                    {/* <td> {lotIdData?.result?.[0]?.crop?.foreign} </td> */}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ marginTop: "2%" }}>
                        <div style={{ color: "black" }}>
                            Search:{" "}
                            <input
                                type={"search"}
                                style={{
                                    border: "1px solid #bfbfbf",
                                    width: "250px",
                                    color: "black",
                                    padding: "5px",
                                }}
                                placeholder="Search by Name , Crop ..."
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            overflow: "auto",
                            marginTop: "2%",
                        }}
                    >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>SNo.</th>
                                    <th>Lot Id</th>
                                    <th>Supp Name</th>
                                    <th>Crop</th>
                                    <th>Status</th>
                                    <th>Expiry Time Edit</th>
                                    <th>Expected Bid</th>
                                    <th>Highest Bid</th>
                                    <th>Total Bid</th>
                                    <th>count on us</th>
                                    <th>Inspection Data </th>
                                    <th>Top 10 Bidder/ Remaining Bidder</th>
                                    <th>Transaction</th>
                                    <th>Value Addition</th>
                                    <th>Action</th>
                                    <th>Delete</th>
                                    <th>Edit Common Bid</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterData?.map((i, index) => (
                                    <tr key={index}>
                                        <td> #{index + 1} </td>
                                        <td
                                            onClick={() => {
                                                fetchSingleBid(i.lotId);
                                            }}
                                            style={{ color: "blue", cursor: "pointer" }}
                                        >
                                            {" "}
                                            {i.lotId}{" "}
                                        </td>
                                        <td> {i.user_id?.tradeName} </td>
                                        <td> {i.crop?.name} </td>
                                        <td> {i.status} </td>
                                        <td> {i.expiretime} </td>
                                        <td> {i.expectedRate} </td>
                                        <td> {i.topBid} </td>
                                        <td> {i.count} </td>
                                        <td> {i.count_on_us == true ? "Applied" : "No"} </td>
                                        <td> {i.lot_inspection} </td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    setId(i._id);
                                                    setUserId(i.user_id._id);
                                                    setShow(true);
                                                }}
                                            >
                                                View
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    setId(i._id);
                                                    setModalShow2(true);
                                                }}
                                            >
                                                View
                                            </Button>
                                        </td>
                                        <td>
                                            {" "}
                                            {i.valueAdditionData.length === 0 ||
                                                i.valueAdditionData == null ? (
                                                "--"
                                            ) : (
                                                <Button
                                                    onClick={() => {
                                                        setBidId(i._id);
                                                        setModalShow3(true);
                                                    }}
                                                >
                                                    View
                                                </Button>
                                            )}{" "}
                                        </td>
                                        <td>
                                            <i
                                                className="fa-solid fa-pen-to-square"
                                                style={{ color: "blue", cursor: "pointer" }}
                                                onClick={() => {
                                                    setId(i._id);
                                                    setModalShow(true);
                                                }}
                                            ></i>
                                        </td>
                                        <td>
                                            <i
                                                className="fa-solid fa fa-trash"
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => {
                                                    deleteData(i._id)
                                                }}
                                            ></i>
                                        </td>
                                        <td>
                                            <i
                                                className="fa-solid fa fa-pen"
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => {
                                                    setDataToEdit(i);
                                                    setIdToEdit(i._id);
                                                    setModalShow4(true);
                                                }}
                                            ></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            )}
        </>
    );
};

export default HOC(CommonBid);
